<template>
  <div>
    <div class="body">
      <div class="intro">
        <div>Hi 👋</div>
        <div v-if="islogin" class="intro-sub">{{ userinfo.nickname }}</div>
        <div class="intro-sub">欢迎使用 Justap</div>
      </div>
      <div class="bio">tagid: {{ tag }}</div>
      <div style="padding-top: 5vh"></div>
      <div class="bind-btn">
        <van-button
          v-on:click="bind()"
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          size="large"
          >绑定 Justtap</van-button
        >
      </div>
      <div
        v-on:click="notnow()"
        style="
          padding-top: 2vh;
          font-size: 1.5vh;
          color: #c42e23;
          text-align: center;
        "
      >
        现在不要
      </div>
    </div>
    <van-popup
      class="popup"
      v-model="showbind"
      round
      closeable
      position="bottom"
      :style="{ height: '90%' }"
    >
      <div class="intro-bind black-font">
        <div>绑定</div>
        <div class="intro-sub-bind black-font">将 Justap 与您的账号连接</div>
        <div style="padding-top: 5vh"></div>
        <van-steps direction="vertical" :active="bindstep">
          <van-step>
            <h3>登入/注册账号</h3>
          </van-step>
          <van-step>
            <h3>再次扫描 Justap</h3>
          </van-step>
          <van-step>
            <h3>点击下方绑定按钮</h3>
          </van-step>
        </van-steps>
        <div style="padding-top: 5vh"></div>
        <van-button
          v-if="islogin"
          :disabled="bindbutton"
          v-on:click="bindaction()"
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          size="large"
          >和 {{ userinfo.nickname }} 绑定</van-button
        >
        <van-button
          v-else
          v-on:click="tologin()"
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          size="large"
          >登入</van-button
        >
      </div>
    </van-popup>
  </div>
</template>
<script>
import { bind } from "@/api/store";

// let _this = this
export default {
  name: "NewTag",
  data() {
    return {
      loading: true,
      userinfo: {
        nickname: "",
      },
      bindstep: 0,
      islogin: false,
      showbind: false,
      bindbutton: true,
      tag: "",
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      // 请求信息
      this.tag = localStorage.getItem("newtagid");
      this.userinfo = JSON.parse(localStorage.getItem("userinfo"));

      if (localStorage.getItem("token") != null) {
        this.bindstep = 1;
        this.islogin = true;
      }

      if (localStorage.getItem(this.tag) == 1) {
        localStorage.removeItem("step");
        this.bindstep = 2;
        this.bindbutton = false;
      }
    },
    tologin() {
      this.$router.push({ name: "Login" });
    },
    bind() {
      this.showbind = true;
      localStorage.setItem(this.tag, 1);
    },
    notnow() {
      this.$router.push({ name: "About" });
    },
    bindaction() {
      bind({ uuid: this.userinfo.uuid, tagid: this.tag }).then((response) => {
        this.$toast.loading({
          message: "加载中...",
          forbidClick: true,
        });
        if (response.data.errno == 0) {
          this.$toast.success("成功绑定");
          localStorage.removeItem(this.tag);
          localStorage.removeItem("newtagid");
          this.$router.push({ path: "hi/" + this.tag });
        } else if (response.data.errno == 1404) {
          this.$toast.fail("标签未售出");
        } else if (response.data.errno == 1409) {
          this.$toast.fail("标签已经绑定其他用户");
        }
      });
    },
  },
};
</script>
<style>
.black-font {
  color: black !important;
  /* background: linear-gradient(to right, rgb(255, 96, 52), rgb(238, 10, 36)) !important; */
}
.popup {
  background-color: rgba(255, 255, 255, 1);
}
.blur {
  background: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.1) 75%,
    rgba(0, 0, 0, 1) 100%
  );
  display: inline-block;
}
.body {
  background-color: #030303;
  height: 100vh;
}
.intro {
  color: white;
  padding-left: 5vh;
  padding-top: 10vh;
  font-size: 5vh;
}
.intro-bind {
  color: white;
  padding-left: 4vh;
  padding-right: 4vh;
  padding-top: 10vh;
  font-size: 4vh;
}
.intro-sub {
  color: white;
  padding-top: 1vh;
  font-size: 3vh;
}
.intro-sub-bind {
  color: white;
  padding-top: 1vh;
  font-size: 2vh;
}
.bio {
  color: white;
  padding-left: 5vh;
  padding-right: 5vh;
  padding-top: 4vh;
  padding-bottom: 4vh;
}
.bind-btn {
  margin-left: 3vh;
  margin-right: 3vh;
}
.van-grid-item__content {
  border-radius: 10%;
}
.van-grid-item__icon {
  font-size: 6vh !important;
}
.van-icon__image {
  width: 1em;
  height: 1em;
  border-radius: 20%;
  object-fit: contain;
}
.van-grid-item__content--surround::after {
  border-width: 0px;
}
.van-grid-item__content {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  padding: 16px 8px;
  background-color: #000;
}
.van-grid-item__text {
  color: #ffffff;
  font-size: 12px;
  line-height: 1.5;
  word-break: break-all;
}
</style>